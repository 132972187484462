import { Injectable } from '@angular/core';
import { BackendService } from '../backend.service';
import { Stats } from '../../types/stats.interface';

@Injectable()
export class PriceMetricApiService {
  public constructor(private backendService: BackendService) {}

  public getWarehousePrice(branchUid: string, days: number): Promise<Stats[]> {
    return this.backendService.get<Stats[]>(
      `/stats/warehouse-price/${this.backendService.userUid}/${branchUid}/${days}`,
      undefined,
      {
        version: 'v1'
      }
    );
  }

  public getOrderPrice(branchUid: string, days: number): Promise<Stats[]> {
    return this.backendService.get<Stats[]>(
      `/stats/order-price/${this.backendService.userUid}/${branchUid}/${days}`,
      undefined,
      {
        version: 'v1'
      }
    );
  }
}
