import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { MonoTypeOperatorFunction, Observable, pipe } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AppStateService } from './app-state.service';
import { ContextOptions } from '@ngneat/cashew/lib/cache-context';
import { withCache } from '@ngneat/cashew';

export interface HttpResponseProblem {
  status: number;
  messages: string;
  errors: string;
}

@Injectable()
export class BackendService {
  public constructor(
    private http: HttpClient,
    private appStateService: AppStateService
  ) {}

  public get<T>(
    path: string,
    httpOptions?: object,
    cache?: ContextOptions
  ): Promise<T> {
    return this.http
      .get<T>(this.url(path), {
        ...httpOptions,
        context: cache ? withCache(cache) : undefined
      })
      .pipe(this.retryOrTrow())
      .toPromise();
  }

  public post<T>(
    path: string,
    body: unknown,
    httpOptions?: object
  ): Promise<T> {
    return this.http
      .post<T>(this.url(path), body, httpOptions)
      .pipe(this.retryOrTrow())
      .toPromise();
  }

  public request<T>(request: HttpRequest<T>): Observable<HttpEvent<T>> {
    return this.http.request(request);
  }

  public put<T>(path: string, body: unknown, options?: object): Promise<T> {
    return this.http
      .put<T>(this.url(path), body, options)
      .pipe(this.retryOrTrow())
      .toPromise();
  }

  public delete<T>(path: string, options?: object): Promise<T> {
    return this.http
      .delete<T>(this.url(path), options)
      .pipe(this.retryOrTrow())
      .toPromise();
  }

  private retryOrTrow<T>(): MonoTypeOperatorFunction<T> {
    return pipe(
      catchError((problem) => {
        throw {
          status: problem.error.status,
          messages: problem.error.messages,
          errors: problem.error.errors
        } as HttpResponseProblem;
      })
    );
  }

  public url(path = ''): string {
    if (isDevMode()) {
      console.info('API call: ', path);
    }
    return `${environment.apiUrl}${path ? path : ''}`;
  }

  public get userUid(): string {
    return this.appStateService.userUid;
  }
}
