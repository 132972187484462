import { Injectable } from '@angular/core';
import { Branch, FreeTermInterval } from '../../types/branch.interface';
import { Customer } from '../../types/customer.interface';
import { BackendService } from '../backend.service';
import { FreeTermListRequest } from '../../types/reservation.interface';
import { BasicFilter } from '../../types/filters.interface';

@Injectable()
export class BranchApiService {
  public constructor(private backendService: BackendService) {}

  public getBranches(
    filter: BasicFilter = {},
    userUid: string | null = null
  ): Promise<Branch[]> {
    return <Promise<Branch[]>>this.backendService.get(
      `/branch/list/${userUid ? userUid : this.backendService.userUid}`,
      {
        params: { ...filter }
      }
    );
  }

  public getBranch(branchUid: string): Promise<Branch> {
    return this.backendService.get<Branch>(`/branch/detail/${branchUid}`);
  }

  public addBranch(values: Branch): Promise<void> {
    return this.backendService.post<void>(
      `/branch/create/${this.backendService.userUid}`,
      values
    );
  }

  public updateBranch(uid: string, values: Customer): Promise<void> {
    return this.backendService.put<void>(
      `/branch/edit/${uid}/${this.backendService.userUid}`,
      values
    );
  }

  public deleteBranch(uid: string): Promise<void> {
    return this.backendService.delete<void>(
      `/branch/delete/${uid}/${this.backendService.userUid}`
    );
  }

  public getFreeTerms(
    options: FreeTermListRequest
  ): Promise<FreeTermInterval[]> {
    return this.backendService.post<FreeTermInterval[]>(
      `/branch/free-term-list`,
      options
    );
  }
}
