import { Injectable } from '@angular/core';
import { Car } from '../../types/car.interface';
import { BackendService } from '../backend.service';
import { CarFilter } from '../../types/filters.interface';

@Injectable()
export class CarApiService {
  public constructor(private backendService: BackendService) {}

  public getCars(filter: CarFilter = {}): Promise<Car[]> {
    return <Promise<Car[]>>this.backendService.get(
      '/car/list',
      {
        params: { ...filter }
      },
      {
        version: 'v1',
        ttl: 86400000 * 10 // 10 day
      }
    );
  }
}
