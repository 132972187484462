<div mat-dialog-content class="content">
  <img [src]="data.image">
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>
    <mat-icon>cancel</mat-icon>
    <mat-icon>close</mat-icon>
    {{'global.cancel' | translate}}
  </button>
</div>



