import { Injectable } from '@angular/core';
import { WarehouseMap } from '../../types/warehouseMap.interface';
import { BackendService } from '../backend.service';
import { WarehouseMapFilter } from '../../types/filters.interface';

@Injectable()
export class WarehouseMapApiService {
  public constructor(private backendService: BackendService) {}

  public getWarehouseMaps(
    filter: WarehouseMapFilter = {},
    order: string | null = null
  ): Promise<WarehouseMap[]> {
    return <Promise<WarehouseMap[]>>this.backendService.get(
      `/warehouse-map/list/${this.backendService.userUid}`,
      {
        params: { ...filter, order }
      }
    );
  }

  public addWarehouseMap(
    values: WarehouseMap | unknown
  ): Promise<WarehouseMap> {
    return this.backendService.post<WarehouseMap>(
      `/warehouse-map/create/${this.backendService.userUid}`,
      values
    );
  }

  public deleteWarehouseMap(uid: string): Promise<void> {
    return this.backendService.delete<void>(
      `/warehouse-map/delete/${uid}/${this.backendService.userUid}`
    );
  }

  public getWarehouseMap(uid: string): Promise<WarehouseMap> {
    return this.backendService.get<WarehouseMap>(
      `/warehouse-map/detail/${uid}/${this.backendService.userUid}`
    );
  }

  public updateWarehouseMap(
    uid: string,
    values: WarehouseMap | unknown
  ): Promise<void> {
    return this.backendService.put<void>(
      `/warehouse-map/edit/${uid}/${this.backendService.userUid}`,
      values
    );
  }
}
