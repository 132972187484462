import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { SnackBarNotificationService } from '../snack-bar-notification/snack-bar-notification.service';
import { PersonalInfoResponse } from '../../types/profile.interface';
import { ProfileApiService } from '../endpoints/profile-api.service';

@Injectable()
export class ProfilePersonalResolver implements Resolve<PersonalInfoResponse> {
  public constructor(
    private profileApiService: ProfileApiService,
    private notificationService: SnackBarNotificationService
  ) {}

  public resolve(): Observable<PersonalInfoResponse> {
    return from(this.profileApiService.getPersonalInfo()).pipe(
      catchError((e) => {
        this.notificationService.showErrorMessage('Chyba');

        throw e;
      })
    );
  }
}
