import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { BillingApiService } from '../endpoints/billing-api.service';
import { LicenseInfo, TotalLicensePrice } from '../../types/billing.interface';
import { from, Observable } from 'rxjs';
import { SnackBarNotificationService } from '../snack-bar-notification/snack-bar-notification.service';

@Injectable()
export class LicenseResolver
  implements Resolve<LicenseInfo & TotalLicensePrice>
{
  public constructor(
    private billingApiService: BillingApiService,
    private notificationService: SnackBarNotificationService
  ) {}

  public resolve(): Observable<LicenseInfo & TotalLicensePrice> {
    return from(this.billingApiService.getLicenseInfo()).pipe(
      catchError((e) => {
        this.notificationService.showErrorMessage(
          'Chyba při načítaní licence',
          []
        );

        throw e;
      })
    );
  }
}
