import { Injectable } from '@angular/core';
import { Branch } from '../../types/branch.interface';
import { BackendService } from '../backend.service';
import { FindCustomerRequest } from '../../types/customer.interface';
import { FindCustomerResponse } from '../../types/reservation.interface';

@Injectable()
export class ReservationApiService {
  public constructor(private backendService: BackendService) {}

  public getBranch(id: number, url: string): Promise<Branch> {
    return this.backendService.get<Branch>(`/reservation/branch/${id}/${url}`);
  }

  public findCustomer(
    licencePlate: string,
    userUid: string,
    values: FindCustomerRequest
  ): Promise<FindCustomerResponse> {
    return this.backendService.post<FindCustomerResponse>(
      `/reservation/find-customer/${licencePlate}/${userUid}`,
      values
    );
  }
}
