<h1 mat-dialog-title>
  {{'auth.reset-password.title' | translate}}
</h1>
<form [formGroup]="resetPasswordForm" (ngSubmit)="onRequestResetPassword()">
  <div mat-dialog-content>
    <mat-form-field class="w-100" appearance="fill">
      <mat-label>{{'global.email' | translate}}</mat-label>
      <input type="email" matInput [formControl]="emailFormControl" placeholder="jan.novak@seznam.cz">
      <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
        {{'global.invalidEmail' | translate}}
      </mat-error>
      <mat-error *ngIf="emailFormControl.hasError('required')">
        {{'global.requiredValue' | translate}}
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>
      <mat-icon>close</mat-icon>
      {{'global.cancel' | translate}}
    </button>
    <button mat-raised-button color="primary" [disabled]="loading || !resetPasswordForm.valid" cdkFocusInitial>
      <mat-icon *ngIf="loading">
        <mat-spinner diameter="20"></mat-spinner>
      </mat-icon>
      {{'auth.reset-password.submit' | translate}}
    </button>
  </div>
</form>

