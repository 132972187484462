import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat';
import UserCredential = firebase.auth.UserCredential;
import { first } from 'rxjs/operators';

@Injectable()
export class AuthService {
  public constructor(
    private httpClient: HttpClient,
    private router: Router,
    private angularFireAuth: AngularFireAuth
  ) {}

  public verifyIdToken(uid: string): Promise<unknown> {
    return this.httpClient
      .get(`${environment.apiUrl}/auth/verify-token/${uid}`)
      .toPromise();
  }

  public async firebaseLogin(
    email: string,
    password: string
  ): Promise<UserCredential> {
    await this.angularFireAuth.setPersistence('local');
    return this.angularFireAuth.signInWithEmailAndPassword(email, password);
  }

  public firebaseResetPassword(email: string): Promise<void> {
    return this.angularFireAuth.sendPasswordResetEmail(email);
  }

  public firebaseSignUp(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return this.angularFireAuth.createUserWithEmailAndPassword(email, password);
  }

  public async logout(): Promise<void> {
    await this.angularFireAuth.signOut();
    localStorage.removeItem('userUid');
    localStorage.removeItem('idToken');
    await this.router.navigate(['/']);
  }

  public getFirebaseUser(): Promise<firebase.User | null> {
    return this.angularFireAuth.authState.pipe(first()).toPromise();
  }

  public async tryRelogin(): Promise<boolean> {
    const firebaseUser = await this.getFirebaseUser();

    if (firebaseUser) {
      const idToken = await firebaseUser?.getIdToken();
      localStorage.setItem('idToken', idToken);
      return true;
    }

    return false;
  }
}
