import { Injectable } from '@angular/core';
import { Task, TaskBundle, TaskTranslations } from '../../types/task.interface';
import { BackendService } from '../backend.service';
import { TaskFilter } from '../../types/filters.interface';
import { TranslateService } from '@ngx-translate/core';
import { unifyPhraseString } from '../../utils';

@Injectable()
export class TaskApiService {
  public constructor(
    private backendService: BackendService,
    private translateService: TranslateService
  ) {}

  public getTask(uid: string): Promise<Task> {
    return this.backendService.get<Task>(`/task/detail/${uid}`);
  }

  public getTasks(
    filter: TaskFilter = {},
    order: string | null = null
  ): Promise<TaskBundle> {
    if (filter.phrase) {
      filter.phrase = this.translateLayer(filter.phrase);
    }

    return <Promise<TaskBundle>>this.backendService.get(
      `/task/list/${this.backendService.userUid}`,
      {
        params: { ...filter, order }
      }
    );
  }

  public updateTask(
    uid: string,
    values: Task | unknown,
    userUid: string | null = null
  ): Promise<void> {
    return this.backendService.put<void>(
      `/task/edit/${uid}/${userUid ? userUid : this.backendService.userUid}`,
      values
    );
  }

  public addTask(
    _values: Omit<Task, 'uid'>,
    userUid: string | null = null
  ): Promise<void> {
    const values: Omit<Task, 'uid'> & TaskTranslations = {
      diskTypeTranslation: this.translateService.instant(
        `admin.branchStand.allDiskTypes.${_values.diskType}`
      ),
      serviceTranslation: this.translateService.instant(
        `admin.branchStand.allServices.${_values.service}`
      ),
      vehicleTypeTranslation: this.translateService.instant(
        `admin.branchStand.allVehicleTypes.${_values.vehicleType}`
      ),
      ..._values
    };

    return this.backendService.post<void>(
      `/task/create/${userUid ? userUid : this.backendService.userUid}`,
      values
    );
  }

  public deleteTask(uid: string, userUid: string | null = null): Promise<void> {
    return this.backendService.delete<void>(
      `/task/delete/${uid}/${userUid ? userUid : this.backendService.userUid}`
    );
  }

  private translateLayer(phrase: string): string {
    const phraseChunks = phrase.split(',');
    let phraseResult = '';

    // service
    const serviceTireServiceWarehouse = unifyPhraseString(
      this.translateService.instant(
        'admin.branchStand.allServices.TIRE_SERVICE_WAREHOUSE'
      )
    );
    const serviceTireServiceTireOverhaulBalance = unifyPhraseString(
      this.translateService.instant(
        'admin.branchStand.allServices.TIRE_SERVICE_TIRE_OVERHAUL_BALANCE'
      )
    );
    const serviceTireServiceTireOverhaulNoBalance = unifyPhraseString(
      this.translateService.instant(
        'admin.branchStand.allServices.TIRE_SERVICE_TIRE_OVERHAUL_NO_BALANCE'
      )
    );
    const serviceTireServiceReplacementBalance = unifyPhraseString(
      this.translateService.instant(
        'admin.branchStand.allServices.TIRE_SERVICE_REPLACEMENT_BALANCE'
      )
    );
    const serviceTireServiceReplacementNoBalance = unifyPhraseString(
      this.translateService.instant(
        'admin.branchStand.allServices.TIRE_SERVICE_REPLACEMENT_NO_BALANCE'
      )
    );
    const serviceTireServiceDefectRepair = unifyPhraseString(
      this.translateService.instant(
        'admin.branchStand.allServices.TIRE_SERVICE_DEFECT_REPAIR'
      )
    );
    const serviceTireServiceTPMSSensorsSale = unifyPhraseString(
      this.translateService.instant(
        'admin.branchStand.allServices.TIRE_SERVICE_TPMS_SENSORS_SALE'
      )
    );
    const serviceTireServiceTPMSSensorsDiagnostics = unifyPhraseString(
      this.translateService.instant(
        'admin.branchStand.allServices.TIRE_SERVICE_TPMS_SENSORS_DIAGNOSTICS'
      )
    );
    const serviceTireServiceDisksRepairRenovationStraightening =
      unifyPhraseString(
        this.translateService.instant(
          'admin.branchStand.allServices.TIRE_SERVICE_DISKS_REPAIR_RENOVATION_STRAIGHTENING'
        )
      );
    const serviceTireServiceDisksRepairRenovationWelding = unifyPhraseString(
      this.translateService.instant(
        'admin.branchStand.allServices.TIRE_SERVICE_DISKS_REPAIR_RENOVATION_WELDING'
      )
    );
    const serviceTireServiceDisksRepairRenovationRenovation = unifyPhraseString(
      this.translateService.instant(
        'admin.branchStand.allServices.TIRE_SERVICE_DISKS_REPAIR_RENOVATION_RENOVATION'
      )
    );
    const serviceAirConditioningOzoneDisinfection = unifyPhraseString(
      this.translateService.instant(
        'admin.branchStand.allServices.AIR_CONDITIONING_OZONE_DISINFECTION'
      )
    );
    const serviceAirConditioningCleaningFilling = unifyPhraseString(
      this.translateService.instant(
        'admin.branchStand.allServices.AIR_CONDITIONING_CLEANING_FILLING'
      )
    );
    const serviceAirConditioningCleaningFillingDisinfection = unifyPhraseString(
      this.translateService.instant(
        'admin.branchStand.allServices.AIR_CONDITIONING_CLEANING_FILLING_DISINFECTION'
      )
    );

    // vehicleType
    const vehicleTypePassenger = unifyPhraseString(
      this.translateService.instant(
        'admin.branchStand.allVehicleTypes.PASSENGER'
      )
    );
    const vehicleTypeCommercial = unifyPhraseString(
      this.translateService.instant(
        'admin.branchStand.allVehicleTypes.COMMERCIAL'
      )
    );
    const vehicleTypeLorry = unifyPhraseString(
      this.translateService.instant('admin.branchStand.allVehicleTypes.LORRY')
    );
    const vehicleTypeMotorcycle = unifyPhraseString(
      this.translateService.instant(
        'admin.branchStand.allVehicleTypes.MOTORCYCLE'
      )
    );
    const vehicleTypeQuadbike = unifyPhraseString(
      this.translateService.instant(
        'admin.branchStand.allVehicleTypes.QUADBIKE'
      )
    );
    const vehicleTypeTrailer = unifyPhraseString(
      this.translateService.instant('admin.branchStand.allVehicleTypes.TRAILER')
    );
    const vehicleTypeMotorhome = unifyPhraseString(
      this.translateService.instant(
        'admin.branchStand.allVehicleTypes.MOTORHOME'
      )
    );
    const vehicleTypeWork = unifyPhraseString(
      this.translateService.instant('admin.branchStand.allVehicleTypes.WORK')
    );
    const vehicleTypeBus = unifyPhraseString(
      this.translateService.instant('admin.branchStand.allVehicleTypes.BUS')
    );

    // diskType
    const diskTypeWithoutDisk = unifyPhraseString(
      this.translateService.instant(
        'admin.branchStand.allDiskTypes.WITHOUT_DISK'
      )
    );
    const diskTypeSteel = unifyPhraseString(
      this.translateService.instant('admin.branchStand.allDiskTypes.STEEL')
    );
    const diskTypeAlu = unifyPhraseString(
      this.translateService.instant('admin.branchStand.allDiskTypes.ALU')
    );
    const diskTypeRunflat = unifyPhraseString(
      this.translateService.instant('admin.branchStand.allDiskTypes.RUNFLAT')
    );
    const diskTypeNone = unifyPhraseString(
      this.translateService.instant('admin.branchStand.allDiskTypes.NONE')
    );

    for (let phraseChunk of phraseChunks) {
      phraseChunk = unifyPhraseString(phraseChunk);

      // service
      if (phraseChunk === serviceTireServiceWarehouse) {
        phraseChunk = 'TIRE_SERVICE_WAREHOUSE';
      }
      if (phraseChunk === serviceTireServiceTireOverhaulBalance) {
        phraseChunk = 'TIRE_SERVICE_TIRE_OVERHAUL_BALANCE';
      }
      if (phraseChunk === serviceTireServiceTireOverhaulNoBalance) {
        phraseChunk = 'TIRE_SERVICE_TIRE_OVERHAUL_NO_BALANCE';
      }
      if (phraseChunk === serviceTireServiceReplacementBalance) {
        phraseChunk = 'TIRE_SERVICE_REPLACEMENT_BALANCE';
      }
      if (phraseChunk === serviceTireServiceReplacementNoBalance) {
        phraseChunk = 'TIRE_SERVICE_REPLACEMENT_NO_BALANCE';
      }
      if (phraseChunk === serviceTireServiceDefectRepair) {
        phraseChunk = 'TIRE_SERVICE_DEFECT_REPAIR';
      }
      if (phraseChunk === serviceTireServiceTPMSSensorsSale) {
        phraseChunk = 'TIRE_SERVICE_TPMS_SENSORS_SALE';
      }
      if (phraseChunk === serviceTireServiceTPMSSensorsDiagnostics) {
        phraseChunk = 'TIRE_SERVICE_TPMS_SENSORS_DIAGNOSTICS';
      }
      if (
        phraseChunk === serviceTireServiceDisksRepairRenovationStraightening
      ) {
        phraseChunk = 'TIRE_SERVICE_DISKS_REPAIR_RENOVATION_STRAIGHTENING';
      }
      if (phraseChunk === serviceTireServiceDisksRepairRenovationWelding) {
        phraseChunk = 'TIRE_SERVICE_DISKS_REPAIR_RENOVATION_WELDING';
      }
      if (phraseChunk === serviceTireServiceDisksRepairRenovationRenovation) {
        phraseChunk = 'TIRE_SERVICE_DISKS_REPAIR_RENOVATION_RENOVATION';
      }
      if (phraseChunk === serviceAirConditioningOzoneDisinfection) {
        phraseChunk = 'AIR_CONDITIONING_OZONE_DISINFECTION';
      }
      if (phraseChunk === serviceAirConditioningCleaningFilling) {
        phraseChunk = 'AIR_CONDITIONING_CLEANING_FILLING';
      }
      if (phraseChunk === serviceAirConditioningCleaningFillingDisinfection) {
        phraseChunk = 'AIR_CONDITIONING_CLEANING_FILLING_DISINFECTION';
      }

      // vehicleType
      if (phraseChunk === vehicleTypePassenger) {
        phraseChunk = 'PASSENGER';
      }
      if (phraseChunk === vehicleTypeCommercial) {
        phraseChunk = 'COMMERCIAL';
      }
      if (phraseChunk === vehicleTypeLorry) {
        phraseChunk = 'LORRY';
      }
      if (phraseChunk === vehicleTypeMotorcycle) {
        phraseChunk = 'MOTORCYCLE';
      }
      if (phraseChunk === vehicleTypeQuadbike) {
        phraseChunk = 'QUADBIKE';
      }
      if (phraseChunk === vehicleTypeTrailer) {
        phraseChunk = 'TRAILER';
      }
      if (phraseChunk === vehicleTypeMotorhome) {
        phraseChunk = 'MOTORHOME';
      }
      if (phraseChunk === vehicleTypeWork) {
        phraseChunk = 'WORK';
      }
      if (phraseChunk === vehicleTypeBus) {
        phraseChunk = 'BUS';
      }

      // diskType
      if (phraseChunk === diskTypeWithoutDisk) {
        phraseChunk = 'WITHOUT_DISK';
      }
      if (phraseChunk === diskTypeSteel) {
        phraseChunk = 'STEEL';
      }
      if (phraseChunk === diskTypeAlu) {
        phraseChunk = 'ALU';
      }
      if (phraseChunk === diskTypeRunflat) {
        phraseChunk = 'RUNFLAT';
      }
      if (phraseChunk === diskTypeNone) {
        phraseChunk = 'NONE';
      }

      phraseResult = `${phraseResult},${phraseChunk}`.trim();
    }

    return phraseResult;
  }
}
