import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Injectable()
export class BreakpointsService implements OnDestroy {
  public device$ = new ReplaySubject<'phone' | 'tablet' | 'desktop'>(1);
  private subscriptions: Subscription[] = [];

  public constructor(private breakpointObserver: BreakpointObserver) {
    const phoneBreakpointSubscription = this.breakpointObserver
      .observe(['(max-width: 600px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.device$.next('phone');
        }
      });

    const tabletBreakpointSubscription = this.breakpointObserver
      .observe(['(min-width: 601px) and (max-width: 992px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.device$.next('tablet');
        }
      });

    const desktopBreakpointSubscription = this.breakpointObserver
      .observe(['(min-width: 992px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.device$.next('desktop');
        }
      });

    this.subscriptions.push(
      phoneBreakpointSubscription,
      tabletBreakpointSubscription,
      desktopBreakpointSubscription
    );
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
