import { Injectable } from '@angular/core';

import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  public constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  public canActivate(): Promise<boolean> {
    const idToken = localStorage.getItem('idToken');
    const userUid = localStorage.getItem('userUid');

    return new Promise(async (resolve) => {
      if (!userUid || !idToken) {
        await this.router.navigate(['/']);
        resolve(false);
      } else {
        const reloginResp = await this.authService.tryRelogin();

        if (reloginResp) {
          resolve(true);
          return true;
        }

        await this.authService.logout();
        resolve(false);
      }
    });
  }

  public canActivateChild() {
    return this.canActivate();
  }
}
