import { Injectable } from '@angular/core';
import { Customer, CustomerBundle } from '../../types/customer.interface';
import { BackendService } from '../backend.service';
import { CustomerFilter } from '../../types/filters.interface';
import { unifyPhraseString } from '../../utils';

@Injectable()
export class CustomerApiService {
  public constructor(private backendService: BackendService) {}

  public getCustomers(
    filter: CustomerFilter = {},
    order: string | null = null
  ): Promise<CustomerBundle> {
    if (filter.phrase) {
      filter.phrase = this.translateLayer(filter.phrase);
    }

    return <Promise<CustomerBundle>>this.backendService.get(
      `/customer/list/${this.backendService.userUid}`,
      {
        params: { ...filter, order }
      }
    );
  }

  public getCustomer(uid: string): Promise<Customer> {
    return this.backendService.get<Customer>(`/customer/detail/${uid}`);
  }

  public addCustomer(
    values: Customer,
    userUid: string | null = null
  ): Promise<Customer> {
    return this.backendService.post<Customer>(
      `/customer/create/${userUid ? userUid : this.backendService.userUid}`,
      values
    );
  }

  public updateCustomer(
    uid: string,
    values: unknown | Customer,
    userUid: string | null = null
  ): Promise<void> {
    return this.backendService.put<void>(
      `/customer/edit/${uid}/${
        userUid ? userUid : this.backendService.userUid
      }`,
      values
    );
  }

  public deleteCustomer(uid: string): Promise<void> {
    return this.backendService.delete<void>(
      `/customer/delete/${uid}/${this.backendService.userUid}`
    );
  }

  protected translateLayer(phrase: string): string {
    const phraseChunks = phrase.split(',');
    let phraseResult = '';

    for (let phraseChunk of phraseChunks) {
      phraseChunk = unifyPhraseString(phraseChunk);
      phraseResult = `${phraseResult},${phraseChunk}`.trim();
    }

    return phraseResult;
  }
}
