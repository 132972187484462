import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  AresCleanResponse,
  SignupRequest,
  SignupResponse
} from '../types/api.interface';
import { map } from 'rxjs/operators';
import * as convert from 'xml-js';
import { BackendService } from './backend.service';

export interface NominatimResponse {
  lat: string;
  lon: string;
}

@Injectable()
export class ApiService {
  public constructor(
    private backendService: BackendService,
    private httpClient: HttpClient
  ) {}

  public signup(values: SignupRequest, uid: string): Promise<SignupResponse> {
    return this.backendService.post<SignupResponse>(
      `/auth/signup/${uid}`,
      values
    );
  }

  public getAresData(_in: string): Promise<AresCleanResponse> {
    return this.httpClient
      .get(`${environment.apiUrl}/ares/${_in}`, {
        responseType: 'text'
      })
      .pipe(
        map((_resp) => {
          const raw = convert.xml2js(_resp, { compact: true }) as any;
          const response: AresCleanResponse = {};

          if (raw['are:Ares_odpovedi']['are:Odpoved']['D:PZA']?._text === '1') {
            const tin =
              raw['are:Ares_odpovedi']['are:Odpoved']['D:VBAS']['D:DIC']?._text;
            const companyName =
              raw['are:Ares_odpovedi']['are:Odpoved']['D:VBAS']['D:OF']?._text;
            const orientationNumber =
              raw['are:Ares_odpovedi']['are:Odpoved']['D:VBAS']['D:AA']['D:CO']
                ?._text;

            if (tin) {
              response.tin = tin;
            }
            if (companyName) {
              response.companyName = companyName;
            }

            response.city =
              raw['are:Ares_odpovedi']['are:Odpoved']['D:VBAS']['D:AA'][
                'D:N'
              ]._text;
            response.zip =
              raw['are:Ares_odpovedi']['are:Odpoved']['D:VBAS']['D:AA'][
                'D:PSC'
              ]._text;
            response.address =
              raw['are:Ares_odpovedi']['are:Odpoved']['D:VBAS']['D:AD'][
                'D:UC'
              ]._text;

            if (orientationNumber) {
              response.address = `${response.address}/${orientationNumber}`;
            }
          } else {
            throw new Error();
          }

          return response;
        })
      )
      .toPromise() as unknown as Promise<AresCleanResponse>;
  }

  public changePassword(uid: string, newPassword: string): Promise<void> {
    return this.backendService.put<void>(
      `/auth/change-password/${uid}/${newPassword}`,
      {}
    );
  }

  public addressToGPS(
    address: string,
    city: string,
    zip: string
  ): Promise<NominatimResponse[]> {
    return this.httpClient
      .get<NominatimResponse[]>(
        `https://nominatim.openstreetmap.org/search?city=${city}&format=json&postalcode=${zip}&street=${address}&limit=1&countrycodes=cz,sk`
      )
      .toPromise();
  }
}
