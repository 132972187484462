import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class AppStateService {
  public isAppLoading = new BehaviorSubject(<undefined | boolean>undefined);
  public refreshBranchesStands = new Subject();

  public get userUid(): string {
    return <string>localStorage.getItem('userUid');
  }
}
