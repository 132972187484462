import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { SnackBarNotificationService } from '../snack-bar-notification/snack-bar-notification.service';
import { Customer } from '../../types/customer.interface';
import { CustomerApiService } from '../endpoints/customer-api.service';

@Injectable()
export class CustomerDetailResolver implements Resolve<Customer> {
  public constructor(
    private customerApiService: CustomerApiService,
    private notificationService: SnackBarNotificationService
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Customer> {
    return from(this.customerApiService.getCustomer(route.params.uid)).pipe(
      catchError((e) => {
        this.notificationService.showErrorMessage(
          'Zákazník nenalezen nebo nastala chyba'
        );

        throw e;
      })
    );
  }
}
