<div class='wrapper' [ngClass]='type'>
  <ng-container *ngIf='type === "success"; else errorIcon'>
    <mat-icon>
      done
    </mat-icon>
  </ng-container>
  <ng-template #errorIcon>
    <mat-icon>
      error
    </mat-icon>
  </ng-template>
  <div>
    <h3>{{data.title}}</h3>
    <p *ngFor='let message of data.messages'>{{message}}</p>
  </div>
</div>
