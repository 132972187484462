import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public constructor(private authService: AuthService) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('idToken');

    if (token) {
      request = request.clone({
        headers: this.addExtraHeaders(request.headers, token)
      });
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.authService.tryRelogin().then((reloginResp) => {
              if (!reloginResp) {
                this.authService.logout();
              }
            });
          }
        }

        return throwError(err);
      })
    );
  }

  protected addExtraHeaders(headers: HttpHeaders, token: string): HttpHeaders {
    headers = headers.append('Authorization', `Bearer ${token}`);

    return headers;
  }
}
