export const environment = {
  firebase: {
    projectId: 'pneusys-988c7',
    appId: '1:937497774760:web:21f6d580c5acff6634509d',
    storageBucket: 'pneusys-988c7.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyB3oGWVbiVK6tuI5aiwpTbKk2InCmcrvJQ',
    authDomain: 'pneusys-988c7.firebaseapp.com',
    messagingSenderId: '937497774760'
  },
  production: false,
  apiUrl: 'https://europe-west1-pneusys-test.cloudfunctions.net/app',
  recaptcha: '6Lc2iDwfAAAAAKjKIUutPt0QuZ1enPDzf55vQbIB',
  gaCode: '',
  stripePublic:
    'pk_test_51LEfxnLoj4PbgoGSsikf6X9JouHXDP3MXbQxMurWinRIGryW2RzRiyN2iE9fMecc6ZMQE0uzcAOKkFk2ZX9VlW4R00RGIeuvmw'
};

import 'zone.js/plugins/zone-error';
