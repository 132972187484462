import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RequestResetPasswordComponent } from './request-reset-password/request-reset-password.component';
import { ImagePreviewComponent } from './image-preview/image-preview.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    TranslateModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  declarations: [RequestResetPasswordComponent, ImagePreviewComponent]
})
export class DialogsModule {}
