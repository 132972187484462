import { Injectable } from '@angular/core';
import { TireManufacturer } from '../../types/tireManufacturer.interface';
import { BackendService } from '../backend.service';
import { TireManufacturerFilter } from '../../types/filters.interface';

@Injectable()
export class TireManufacturerApiService {
  public constructor(private backendService: BackendService) {}

  public getManufacturers(
    filter: TireManufacturerFilter = {}
  ): Promise<TireManufacturer[]> {
    return <Promise<TireManufacturer[]>>this.backendService.get(
      '/tire-manufacturer/list',
      {
        params: { ...filter }
      },
      {
        version: 'v1',
        ttl: 86400000 * 10 // 10 day
      }
    );
  }
}
