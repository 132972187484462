import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class PaginatorIntlService extends MatPaginatorIntl {
  public translate: TranslateService;

  public getRangeLabel = (
    page: number,
    pageSize: number,
    length: number
  ): string => {
    const of = this.translate ? this.translate.instant('paginator.of') : 'of';
    const pageString = this.translate
      ? this.translate.instant('paginator.page')
      : 'Page: ';

    if (length === 0 || pageSize === 0) {
      return `0 ${of} ${length}`;
    }

    const numPages = Math.ceil(length / pageSize);
    return `Celkem: ${length} • ${pageString} ${page + 1} ${of} ${numPages}`;
  };

  public injectTranslateService(translate: TranslateService) {
    this.translate = translate;

    this.translate.onLangChange.subscribe(() => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  public translateLabels() {
    super.itemsPerPageLabel = this.translate.instant('paginator.itemsPerPage');
    super.nextPageLabel = this.translate.instant('paginator.nextPage');
    super.previousPageLabel = this.translate.instant('paginator.previousPage');
    super.lastPageLabel = this.translate.instant('paginator.lastPage');
    super.firstPageLabel = this.translate.instant('paginator.firstPage');
    this.changes.next();
  }
}
