import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface ImagePreviewData {
  image: string;
}

@Component({
  selector: 'app-dialogs-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent {
  public constructor(@Inject(MAT_DIALOG_DATA) public data: ImagePreviewData) {}
}
