import { Injectable } from '@angular/core';
import { BackendService } from '../backend.service';
import { Stats } from '../../types/stats.interface';

@Injectable()
export class CountMetricApiService {
  public constructor(private backendService: BackendService) {}

  public getCustomerCount(branchUid: string, days: number): Promise<Stats[]> {
    return this.backendService.get<Stats[]>(
      `/stats/customer-count/${this.backendService.userUid}/${branchUid}/${days}`,
      undefined,
      {
        version: 'v1'
      }
    );
  }

  public getWarehouseCount(branchUid: string, days: number): Promise<Stats[]> {
    return this.backendService.get<Stats[]>(
      `/stats/warehouse-count/${this.backendService.userUid}/${branchUid}/${days}`,
      undefined,
      {
        version: 'v1'
      }
    );
  }
}
