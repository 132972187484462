import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackBarNotificationComponent } from './snack-bar-notification.component';

export type SnackBarType = 'error' | 'success';
export interface SnackBarData {
  type: SnackBarType;
  title: string;
  messages: string[];
  duration: number;
}

export const SNACK_BAR_DURATION_MS = 5000;

@Injectable()
export class SnackBarNotificationService {
  public constructor(private snackBar: MatSnackBar) {}

  public showErrorMessage(
    title: string,
    messages: string[] = [],
    duration: number = SNACK_BAR_DURATION_MS
  ): void {
    const data: SnackBarData = { type: 'error', title, messages, duration };
    this.openSnackBar(data);
  }

  public showSuccessMessage(
    title: string,
    messages: string[] = [],
    duration: number = SNACK_BAR_DURATION_MS
  ): void {
    const data: SnackBarData = { type: 'success', title, messages, duration };
    this.openSnackBar(data);
  }

  private openSnackBar(data: SnackBarData): void {
    const config: MatSnackBarConfig<SnackBarData> = {
      duration: data.duration,
      verticalPosition: 'top',
      data: data
    };
    this.snackBar.openFromComponent(SnackBarNotificationComponent, config);
  }
}
