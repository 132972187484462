import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';
import { ApiService } from './core/api.service';
import { Branch } from './types/branch.interface';
import { BreakHours } from './shared/selectors/break-hours-selector/break-hours-selector.component';
import { BusinessHours } from './shared/selectors/businnes-hours-selector/business-hours-selector.component';
import _ from 'lodash';
import { SnackBarNotificationService } from './core/snack-bar-notification/snack-bar-notification.service';

export const translatePriority = (
  priority: number,
  translate: TranslateService
): string => {
  let result = '';

  switch (priority) {
    case 0:
      result = translate.instant('global.low');
      break;
    case 1:
      result = translate.instant('global.medium');
      break;
    case 2:
      result = translate.instant('global.high');
      break;
  }

  return result;
};

export const translateState = (
  state: string,
  translate: TranslateService
): string => {
  let result = '';

  switch (state) {
    case 'ACTIVE':
      result = translate.instant('admin.warehouses.states.ACTIVE');
      break;
    case 'INACTIVE':
      result = translate.instant('admin.warehouses.states.INACTIVE');
      break;
    case 'ASSIGNED':
      result = translate.instant('admin.tasks.states.ASSIGNED');
      break;
    case 'IN_PROGRESS':
      result = translate.instant('admin.tasks.states.IN_PROGRESS');
      break;
    case 'WAITING_FOR_SUPPLIER':
      result = translate.instant('admin.tasks.states.WAITING_FOR_SUPPLIER');
      break;
    case 'WAITING_FOR_CUSTOMER':
      result = translate.instant('admin.tasks.states.WAITING_FOR_CUSTOMER');
      break;
    case 'NEED_TASK_SPECIFICATION':
      result = translate.instant('admin.tasks.states.NEED_TASK_SPECIFICATION');
      break;
    case 'DONE':
      result = translate.instant('admin.tasks.states.DONE');
      break;
    case 'ARCHIVED':
      result = translate.instant('admin.tasks.states.ARCHIVED');
      break;
  }

  return result;
};

export const deepEqual = (object1: object, object2: object): boolean => {
  if (!object1 || !object2) {
    return false;
  }

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const val1 = object1[key];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
};

function isObject(object: object): boolean {
  return object != null && typeof object === 'object';
}

export const loadAresValues = async (
  notificationService: SnackBarNotificationService,
  translate: TranslateService,
  form: UntypedFormGroup,
  apiService: ApiService
): Promise<void> => {
  if (form.value.in) {
    try {
      const aresData = await apiService.getAresData(form.value.in);

      form.patchValue(aresData);
    } catch (e) {
      console.error(e);

      notificationService.showErrorMessage(
        translate.instant(`auth.signup.fillInFailed`),
        []
      );
    }
  } else {
    notificationService.showErrorMessage(
      translate.instant(`auth.signup.fillIn`),
      []
    );
  }
};

export const setBusinessHours = (branch: Branch): BusinessHours => {
  return {
    mondayEnd: branch.mondayEnd,
    mondayStart: branch.mondayStart,
    tuesdayEnd: branch.tuesdayEnd,
    tuesdayStart: branch.tuesdayStart,
    wednesdayEnd: branch.wednesdayEnd,
    wednesdayStart: branch.wednesdayStart,
    thursdayEnd: branch.thursdayEnd,
    thursdayStart: branch.thursdayStart,
    fridayEnd: branch.fridayEnd,
    fridayStart: branch.fridayStart,
    saturdayEnd: branch.saturdayEnd,
    saturdayStart: branch.saturdayStart,
    sundayEnd: branch.sundayEnd,
    sundayStart: branch.sundayStart
  };
};

export const setBreakHours = (branch: Branch): BreakHours => {
  return {
    mondayBreakEnd: branch.mondayBreakEnd,
    mondayBreakStart: branch.mondayBreakStart,
    tuesdayBreakEnd: branch.tuesdayBreakEnd,
    tuesdayBreakStart: branch.tuesdayBreakStart,
    wednesdayBreakEnd: branch.wednesdayBreakEnd,
    wednesdayBreakStart: branch.wednesdayBreakStart,
    thursdayBreakEnd: branch.thursdayBreakEnd,
    thursdayBreakStart: branch.thursdayBreakStart,
    fridayBreakEnd: branch.fridayBreakEnd,
    fridayBreakStart: branch.fridayBreakStart,
    saturdayBreakEnd: branch.saturdayBreakEnd,
    saturdayBreakStart: branch.saturdayBreakStart,
    sundayBreakEnd: branch.sundayBreakEnd,
    sundayBreakStart: branch.sundayBreakStart
  };
};

export const dayOfWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
];

export const unifyPhraseString = (phrase: string): string => {
  return _.deburr(phrase.trim().toLowerCase());
};
