import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  Injectable,
  LOCALE_ID,
  NgModule
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { DialogsModule } from './dialogs/dialogs.module';
import { NotFoundComponent } from './404/404.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorIntlService } from './core/paginatorIntl.service';
import { environment } from '../environments/environment';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { AppBaseComponent } from './app-base.component';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule
} from 'ngx-google-analytics';
import {
  HttpCacheInterceptorModule,
  useHttpCacheLocalStorage
} from '@ngneat/cashew';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Injectable()
class MyMissingTranslationHandler implements MissingTranslationHandler {
  public handle(params: MissingTranslationHandlerParams): string {
    return '';
  }
}

@NgModule({
  declarations: [AppComponent, NotFoundComponent, AppBaseComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,

    CoreModule,
    MatSelectCountryModule.forRoot('en'),
    DialogsModule,

    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,

    NgxGoogleAnalyticsModule.forRoot(environment.gaCode),
    NgxGoogleAnalyticsRouterModule,
    HttpCacheInterceptorModule.forRoot(),
    MatProgressSpinnerModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'cs-CZ' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'CZK' },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    },
    {
      provide: MatPaginatorIntl,
      useFactory: (translate: TranslateService) => {
        const service = new PaginatorIntlService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService]
    },
    useHttpCacheLocalStorage,
    {
      provide: MissingTranslationHandler,
      useClass: MyMissingTranslationHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    const lang = localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : 'cs';
    translate.setDefaultLang(lang as string);
    return translate.use(lang as string).toPromise();
  };
}
