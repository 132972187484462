import { Injectable } from '@angular/core';
import { BackendService } from '../backend.service';
import { LicenseInfo, TotalLicensePrice } from '../../types/billing.interface';

@Injectable()
export class BillingApiService {
  public constructor(private backendService: BackendService) {}

  public getLicenseInfo(): Promise<LicenseInfo & TotalLicensePrice> {
    return this.backendService.get<LicenseInfo & TotalLicensePrice>(
      `/billing/license/${this.backendService.userUid}`
    );
  }

  public checkCard(
    cardToken: string
  ): Promise<{ clientSecret: string; cardToken: string }> {
    return this.backendService.post(
      `/billing/check-card/${this.backendService.userUid}/${cardToken}`,
      {}
    );
  }

  public changeLicense(licenseType: 'FREE' | 'PROFI'): Promise<void> {
    return this.backendService.put(
      `/billing/license/${this.backendService.userUid}/${licenseType}`,
      {}
    );
  }

  public checkLicenses(): Promise<void> {
    return this.backendService.get<void>(
      `/billing/check-licenses/${this.backendService.userUid}`
    );
  }
}
